<template>
  <section>
    <b-row>
      <b-col md="4" lg="4" v-for="stat in stats" :key="stat.id">
        <stat :variant="stat.variant" :icon="stat.icon" :name="stat.name" :value="stat.value" :unit="stat.unit"></stat>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="presionesChart00" loadingStrategy="ALWAYS">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <ag-grid-table ref="historicoPresiones" :debug="true" :configUrl="historicoPresionesConfig"
          :dataUrl="historicoPresionesData" @gridReady="onGridReady" @getRowsLoaded="onGetRowsLoaded"
          :paginationPageSize="50">
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
          
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import Stat from '@/views/clients/base/components/Stat.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import axios from "@axios";
var qs = require('qs');

import {
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner
} from "bootstrap-vue";

export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Stat,

    BOverlay,
    BSpinner,

    BrunaElementLoader
  },

  methods: {

    debugChange(value) {
      console.log(value)
      let decoded = JSON.parse(value)
    },

    onGridReady(gridApi, gridColumnApi, params) {
      this.gridApi = gridApi
      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })

    },

    onGetRowsLoaded(gridApi, gridColumnApi, params) {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })

    },

    loadStats00Data() {

      axios.request({
        method: "get",
        url: this.stats00Url,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          this.stats = response.data
        })
    }
  },

  watch: {
    // whenever question changes, this function will run
    busqueda: {
      handler(newValue, oldValue) {
        let query = qs.stringify({ equipo: newValue.equipo, mes: newValue.meses, planta: newValue.planta });
        // let query = qs.stringify({ planta_name: newValue })
        // console.log('QUERY ', newValue.equipo)
        // console.log(query)
        this.historicoPresionesConfig = useApiServices.historicoData.presiones.historicoConfig + "?" + query
        this.historicoPresionesData = useApiServices.historicoData.presiones.historicoData + "?" + query
        this.presionesChart00 = useApiServices.historicoData.presiones.chart00 + "?" + query,
        this.stats00Url = useApiServices.historicoData.presiones.stats00 + "?" + query,
        this.$refs['historicoPresiones'].refreshRows([], true);
        this.loadStats00Data();

      },

    }
  },

  props: {
    busqueda: {
      type: Object,
      required: true,
      default: () => null
    },

  },

  mounted() {
    this.loadStats00Data();
  },

  created() {

  },

  data() {
    let query = qs.stringify({ planta_name: 'Maipu' })

    return {

      showOverlay: false,
      overlayMessage: "Cargando...",

      gridApi: null,

      historicoPresionesConfig: useApiServices.historicoData.presiones.historicoConfig + "?" + query,
      historicoPresionesData: useApiServices.historicoData.presiones.historicoData + "?" + query,
      presionesChart00: useApiServices.historicoData.presiones.chart00 + "?" + query,

      stats00Url: useApiServices.historicoData.presiones.stats00 + "?" + query,

      stats: [],
    };
  },
};
</script>
        
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-slider.scss";

span {
  font-size: 14px;
}

.escenario-base {
  background-color: #00bcd4 !important;
}

.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
          